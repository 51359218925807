/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useReducer } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import {Header2} from "./layout/Header2"
import "./layout.css"
import "../styles/App.css"
import { Helmet } from "react-helmet"
import favicon from '../images/favicon.ico'
// let i18n = require("../i18n/i18n-pt.json")




const Layout = ({ children }) => {
  // const defaultState = {
  //   language: "PT",
  //   i18n: i18n
  // };
  // const [state, dispatch] = useReducer(appReducer, defaultState);
  // const sendEvent = () => {

  // }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <title>eVaz Energy - Mobilidade Elétrica</title>
        <link rel="icon" href={favicon}/>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <meta name="viewport" content="width=device-width, maximum-scale=1, minimum-scale=1" />

      </Helmet>
      {/* <AppContext.Provider value={{state, dispatch, sendEvent}}> */}
        <Header2 siteTitle={data.site.siteMetadata.title} />
        <div 
        // style={{
        //   margin: `0 auto`,
        //   maxWidth: 960,
        //   padding: `0 1.0875rem 1.45rem`,
        // }}
        >
          <main>
            {children}
          </main>
          
        </div>
      {/* </AppContext.Provider> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
