import { Link } from 'gatsby'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext'
import DefaultButton from '../common/Button'

const Container = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export default function LoginButton() {
    const { appState } = useContext(AppContext);

    return (
        <Container>
            <Link to={"/login"}>
                <DefaultButton primary={0} label={appState.i18n.all.login} width={"5rem"}/>
            </Link>
        </Container>
    )
}
