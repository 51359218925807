import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { HowMenu } from './header/HowMenu';
import { SolutionsMenu } from './header/SolutionsMenu';
import { AboutMenu } from './header/AboutMenu';
import { Tab } from './header/Tab';
import evazLogo from "./logo.png";
import logo from "./logo.png";
import { AppContext } from '../../contexts/AppContext';
import ReactCountryFlag from 'react-country-flag';
import { SET_I18N_FILE, SET_LANGUAGE } from '../../reducers/AppReducer';
import { Link } from "gatsby";
import DefaultButton from '../common/Button'
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import StoreIcon from '@material-ui/icons/Store';
import Home from '@material-ui/icons/Home';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import HelpIcon from '@material-ui/icons/Help';
import PublicIcon from '@material-ui/icons/Public';
import { Collapse, ListSubheader } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LoginButton from './LoginButton';


const Viewport = styled.div`
    width: 100%;
`

const Frame = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 70px;

    &::before {
        content: '';
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        background: rgba(255, 255, 255, 0.93);
        z-index: -999;
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
    }
`

const LogoContainer = styled.div`
    align-items: center;
    display: flex;
    margin: 30px;
`

const Logo = styled.img`
    width: 100px;
    height: auto;
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;

    @media(max-width: 1000px) {
        width: auto;
    }
`

const TabContainer = styled.div`
    display: flex;
    justify-content: left;
    height: 70px;
`

const RegButtonContainer = styled.div`
    margin: 0 30px !important;

@media(max-width: 1000px) {
    display: none;
}
`

const LeftMenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

@media(max-width: 1000px) {
    display: none;
}
`

const RightMenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const SidebarToggleContainer = styled.div`
    margin-left: 2rem;
    display: none;

    @media(max-width: 1000px) {
        display: block;
    }
`

const ListItemIcon_ = styled(ListItemIcon)`
    min-width: 40px;
`


const SidebarCountrySelector = styled.div`
    width: 250px;
    z-index: 1;
    position: fixed;
    bottom: 0;
    background-color: ${props => props.theme.default.colors.secondary};
`

const Drawer_ = styled(Drawer)`
    padding-bottom: 30px;
`

const Contacts = styled.div`
    padding-bottom: 55px;
`

const CountrySelectorWrapperTop = styled.div`
    @media(max-width: 1000px) {
        display: none;
    }
`

const HBox = styled.div`
    display: flex;
    flex-direction: row;
`



export const Header2 = ({location}) => {

    // STATE DECLARATIONS

    const [sidebarState, setSidebarState] = useState(false);
    const { appState, dispatch, sendEvent } = useContext(AppContext);
    const [openPublicSolutions, setOpenPublicSolutions] = useState(false);
    const [openPrivateSolutions, setOpenPrivateSolutions] = useState(false);
    const [openEnergeticTransition, setOpenEnergeticTransition] = useState(false);
    const [openTips, setOpenTips] = useState(false);
    const [openAbout, setOpenAbout] = useState(false);


    const handlePublicSolutionsClick = () => {
        setOpenPublicSolutions(!openPublicSolutions);
        sendEvent("Navigation", "Sidebar", "Open Public Solutions")
    };

    const handlePrivateSolutionsClick = () => {
        setOpenPrivateSolutions(!openPrivateSolutions);
        sendEvent("Navigation", "Sidebar", "Open Private Solutions")
    };

    const handleEnergeticTransitionClick = () => {
        setOpenEnergeticTransition(!openEnergeticTransition);
        sendEvent("Navigation", "Sidebar", "Open Energetic Transition")
    };

    const handleTipsClick = () => {
        setOpenTips(!openTips);
        sendEvent("Navigation", "Sidebar", "Open Tips")
    };

    const handleAboutClick = () => {
        setOpenAbout(!openAbout);
        sendEvent("Navigation", "Sidebar", "Open About")
    };

    const onClickAnywhere = (e) => {
        toggleDrawer(false)
    }

    // LOGIC

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) { return; }
        setSidebarState(open);
    };

    const setLanguage = (lang) => {
        dispatch({ type: SET_I18N_FILE, payload: { language: lang } });
        dispatch({ type: SET_LANGUAGE, payload: { language: lang } });
    }


    // SIDEBAR STYLING

    const useStyles = makeStyles(theme => ({
        list: {
            width: 250,
        },
        fullList: {
            width: 'auto',
        },
        nested: {
            paddingLeft: theme.spacing(7),
        },
        nestedText: {
            fontSize: '11pt !important'
        },
        subheader: {
            marginTop: '20px'
        }
    }));

    const classes = useStyles();

    const isAccountPage = typeof window !== "undefined" && window.location.pathname.includes("account")

    // COUNTRY SELECTOR COMPONENT

    const CountrySelector = ({ place }) => {
        return <List>
                <ListItem>
                    <div onClick={() => {
                        setLanguage("EN");
                        // openToaster(appState.i18n.lang.selectedEN)
                        sendEvent("Language", place, "EN")
                    }}>
                        <ReactCountryFlag className="header-flag"
                            style={{
                                width: '1.5em',
                                height: '1.5em',
                            }}
                            countryCode="GB" svg />
                    </div>
                    <ReactCountryFlag className="header-flag"
                        onClick={() => {
                            setLanguage("PT")
                            // openToaster(appState.i18n.lang.selectedPT)
                            sendEvent("Language", place, "PT")
                        }}
                        style={{
                            width: '1.5em',
                            height: '1.5em',
                        }}
                        countryCode="PT" svg />
                </ListItem>
            </List>
        
    }


    const list = () => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: false,
            })}
            style={{ height: "inherit" }}
            role="presentation"
            onClick={(e) => onClickAnywhere(e)}
            onKeyDown={(e) => onClickAnywhere(e)}>
            <div className="vbox sidebar-container">
                <div>
                    <List className="sidebar-toplist-container">
                        <div className="sidebar-evaz-logo">
                            <img src={logo} className="sidebar-logo-img" alt="Logo" />
                        </div>
                        <div className="sidebar-register-button-cont">
                            <Link onClick={() => sendEvent("Navigation", "Sidebar", "Get card")} to="/register"><DefaultButton label={appState.i18n["get_card"].toUpperCase()} variant="contained" /></Link>
                        </div>
                    </List>
                    <List>
                        <AnchorLink smooth="true" to="/#start">
                            <ListItem button onClick={() => sendEvent("Navigation", "Sidebar", "Start")} key="start">
                                <ListItemIcon_><Home className="sidebar-list-icon" /></ListItemIcon_>
                                <ListItemText primary={appState.i18n.sidebar.start} />
                            </ListItem>
                        </AnchorLink>

                        {/* MARKETPLACE */}
                        {/* <Link to="/marketplace">
                            <ListItem button onClick={() => sendEvent("Navigation", "Sidebar", "Marketplace")} key="marketplace">
                                <ListItemIcon_><StoreIcon className="sidebar-list-icon" /></ListItemIcon_>
                                <ListItemText primary={appState.i18n.navs.marketplace} />
                            </ListItem>
                        </Link> */}


                        <ListSubheader className={classes.subheader} component="div" id="nested-list-subheader">
                            {appState.i18n.navs.services}
                        </ListSubheader>

                        {/********************* PRIVATE SOLUTIONS ******************/}

                        <ListItem button onClick={handlePrivateSolutionsClick}>
                            <ListItemIcon_><Home className="sidebar-list-icon" /></ListItemIcon_>
                            <ListItemText primary={appState.i18n.navs.solutions_private} />
                            {openPrivateSolutions ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openPrivateSolutions} timeout="auto">
                            <Link to="/solutions/private/powerpack">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "PowerPack")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.plan_Powerpack} />
                                    </ListItem>
                                </List>
                            </Link>
                            {/* <Link to="/solutions/private/powerbox">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "PowerBox")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.plan_Powerbox} />
                                    </ListItem>
                                </List>
                            </Link> */}
                        </Collapse>

                        {/********************* PUBLIC SOLUTIONS ******************/}

                        <ListItem button onClick={handlePublicSolutionsClick}>
                            <ListItemIcon_><PublicIcon className="sidebar-list-icon" /></ListItemIcon_>
                            <ListItemText primary={appState.i18n.navs.solutions_public} />
                            {openPublicSolutions ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openPublicSolutions} timeout="auto">
                        <Link to="/solutions/plans/">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "Plan Comparator")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.plan_comparator} />
                                    </ListItem>
                                </List>
                            </Link>
                            <Link to="/solutions/plans/mobility">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "Mobility Plan")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.plan_move} />
                                    </ListItem>
                                </List>
                            </Link>
                            <Link to="/solutions/plans/residential">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "Residential Plan")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.plan_residential} />
                                    </ListItem>
                                </List>
                            </Link>
                        </Collapse>


                        {/* HOW IT WORKS */}
                        <ListSubheader className={classes.subheader} component="div" id="nested-list-subheader">
                            {appState.i18n.navs.howItWorks}
                        </ListSubheader>
                        <ListItem button onClick={handleEnergeticTransitionClick}>
                            <ListItemIcon_><TrackChangesIcon className="sidebar-list-icon" /></ListItemIcon_>
                            <ListItemText primary={appState.i18n.navs.energeticTransition} />
                            {openEnergeticTransition ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openEnergeticTransition} timeout="auto">
                            <Link to="/how/circular-economy">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "Circular Economy")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.circularEconomy} />
                                    </ListItem>
                                </List>
                            </Link>
                            {/* <Link to="/how/advantages-vs-fossil">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "Advantages vs Fossil")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.advantagesVsFossil} />
                                    </ListItem>
                                </List>
                            </Link> */}
                        </Collapse>

                        <ListItem button onClick={handleTipsClick}>
                            <ListItemIcon_><DoneAllIcon className="sidebar-list-icon" /></ListItemIcon_>
                            <ListItemText primary={appState.i18n.navs.tips} />
                            {openTips ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>

                        <Collapse in={openTips} timeout="auto">
                            <Link to="/how/public-charging">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "Public Charging")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.publicCharging} />
                                    </ListItem>
                                </List>
                            </Link>
                            <Link to="/how/private-charging">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "Private Charging")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.privateCharging} />
                                    </ListItem>
                                </List>
                            </Link>
                            {/* <Link to="/how/invoice-in-detail">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Header", "Detailed Invoice")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.detailedInvoice} />
                                    </ListItem>
                                </List>
                            </Link> */}
                        </Collapse>


                        {/* ABOUT */}
                        <ListSubheader component="div" className={classes.subheader} id="nested-list-subheader">
                            {appState.i18n.navs.more}
                        </ListSubheader>
                        <ListItem button onClick={handleAboutClick}>
                            <ListItemIcon_><HelpIcon className="sidebar-list-icon" /></ListItemIcon_>
                            <ListItemText primary={appState.i18n.navs.aboutUs} />
                            {openAbout ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={openAbout} timeout="auto">
                            <Link to="/about/faqs">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Sidebar", "FAQs")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.faq} />
                                    </ListItem>
                                </List>
                            </Link>
                            {/* <Link to="/how/our-mission">
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} onClick={() => sendEvent("Navigation", "Header", "Our Mission")}>
                                        <ListItemText className={classes.nestedText} primary={appState.i18n.navs.mission} />
                                    </ListItem>
                                </List>
                            </Link> */}
                        </Collapse>

                    </List>
                </div>
                <Contacts>
                    <AnchorLink smooth to="/#contacts">
                        <ListItem onClick={() => sendEvent("Navigation", "Sidebar", "Contact Us")} button key='contacts'>
                            <ListItemIcon><MailIcon /></ListItemIcon>
                            <ListItemText primary={appState.i18n.navs.contacts} />
                        </ListItem>
                    </AnchorLink>
                </Contacts>
                <SidebarCountrySelector>
                    <Divider />
                    <HBox>
                        <CountrySelector place={"Sidebar"} />
                        {/* <LoginButton /> */}
                    </HBox>
                </SidebarCountrySelector>
            </div>
        </div>
    );

    return (
        <Viewport>
            <Drawer_ anchor="left" open={sidebarState} onClose={toggleDrawer(false)}>
                {list()}
            </Drawer_>
            <Frame>
                <LogoContainer>
                    <AnchorLink smooth to="/#start">
                        <Logo src={evazLogo} />
                    </AnchorLink>
                </LogoContainer>
                {!isAccountPage ? <ActionsContainer>
                    <LeftMenuContainer>
                        <TabContainer>
                            <Tab name={appState.i18n.navs.solutions} Menu={SolutionsMenu} />
                            {/* <Tab name={appState.i18n.navs.marketplace} /> */}
                            <Tab name={appState.i18n.navs.howItWorks} Menu={HowMenu} />
                            <Tab name={appState.i18n.navs.about} Menu={AboutMenu} />
                        </TabContainer>
                    </LeftMenuContainer>
                    <RightMenuContainer>
                        {/* <LoginButton /> */}
                        <CountrySelectorWrapperTop>
                            <CountrySelector place={"Sidebar"} />
                        </CountrySelectorWrapperTop>
                        <RegButtonContainer>
                            <Link to="/register" onClick={() => sendEvent("Navigation", "Header", "Get card")}>
                                <DefaultButton label={appState.i18n["get_card"].toUpperCase()} />
                            </Link>
                        </RegButtonContainer>
                        <SidebarToggleContainer onClick={() => sendEvent("Navigation", "Header", "Burger Menu")}>
                            <Button onClick={toggleDrawer(true)}><FontAwesomeIcon className="header-burguer-icon" icon={faBars} /></Button>
                        </SidebarToggleContainer>
                    </RightMenuContainer>
                </ActionsContainer> : null}
            </Frame>
        </Viewport>
    )
}
