import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import styled from 'styled-components'


const TabContainer = styled.div`
        position: relative;
    `

const MenuTab = styled(Button)`
        height: 100%;
        padding: 0 40px !important;
        border-radius: 0 !important;
        white-space: nowrap;
        color: ${props => props.open ? props.theme.default.colors.primary : props.theme.default.colors.darkGrey} !important;
        transition: color 0.25s;
        background-color: ${props => props.open ? props.theme.default.colors.headerMenu : "transparent"};
        /* background-color: ${props => props.open ? props.theme.default.colors.headerMenu : props.theme.default.colors.secondary} !important; */
        &:hover {
            /* transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
            background-color: ${props => props.theme.default.colors.headerMenu};
        }
    `


export const Tab = ({ name, Menu }) => {
    
    const [open, setOpen] = useState(false)
    // const open = Boolean(anchorEl);

    return (
        <TabContainer
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onClick={() => setOpen(true)}
        >
            <MenuTab
                aria-owns={open ? 'mouse-over-popover-' + name : undefined}
                aria-haspopup="true" 
                open={open}
            >
                {name}
            </MenuTab>
            {Menu && <Menu open={open} name={name} />}
        </TabContainer>
    )
}
