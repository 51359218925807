import { Link } from 'gatsby'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../../contexts/AppContext'

const Dropdown = styled.div`
        font-family: ${props => props.theme.default.fonts.family};
        position: absolute;
        min-width: 300px;
        background-color: ${props => props.theme.default.colors.headerMenu};
    `

const OptionList = styled.ul`
        list-style-type: none;
        margin: 0;
        padding: 0;
    `

const Option = styled.li`
        padding: 20px 40px !important;
        font-size: 10pt;
        text-transform: uppercase;
        margin-bottom: 0px !important;
        cursor: pointer;
        color: ${props => props.theme.default.colors.darkTeal};

        &:hover {
            background-color: rgba(0,0,0,0.05)
        }
    `

export const AboutMenu = ({ open }) => {

    const { appState, sendEvent } = useContext(AppContext);

    return (
        open && <Dropdown>
            {/* <MenuSection>Soluções Públicas</MenuSection>
            <MenuSectionText>Transição energética para mobilidade elétrica em casa ou empresa</MenuSectionText> */}
            <OptionList>
                <Link to="/about/faqs" onClick={() => sendEvent("Navigation", "Header", "FAQs")}>
                    <Option>{appState.i18n.header.faqs}</Option>
                </Link>
                {/* <Link to="/about/our-mission" onClick={() => sendEvent("Navigation", "Header", "Our Mission")}>
                    <Option>{appState.i18n.header.ourMission}</Option>
                </Link> */}
            </OptionList>

        </Dropdown>
    )
}
